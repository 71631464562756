@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Pretendard";
    src: url("../../public/fonts/Pretendard-Regular.otf") format("opentype"); /* public 폴더에서 폰트 파일을 불러옵니다. */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("../../public/fonts/Pretendard-SemiBold.otf") format("opentype"); /* bold 폰트 */
    font-weight: 700;
    font-style: normal;
}

* {
    font-family: "Pretendard", sans-serif;
    src: url("../../public/fonts/Pretendard-Regular.otf") format("opentype");
    font-weight: 400;
    font-size: 16px;
    transition: all 150ms;
}

/* 폰트 */
.sg-tx-medium {
    font-weight: 500;
}

.sg-tx-semi-bold {
    font-weight: 600;
}

/* footer */
.sg-footer-container {
    width: 100%;
    padding-top: clamp(40px, 5.2vw, 5.2vw);
    padding-bottom: clamp(60px, 6.3vw, 6.3vw);
    background-color: #292929;
}
.sg-amuz-logo {
    width: clamp(116px, 7.1vw, 7.1vw);
    height: clamp(29px, 1.8vw, 1.8vw);
}

.sg-tx-footer-amuz-box {
    height: clamp(29px, 1.8vw, 1.8vw);
    display: flex;
    align-items: center;
    margin-bottom: clamp(0px, 1.3vw, 1.3vw);
}

.sg-tx-footer-white {
    font-weight: 600;
    font-size: clamp(14px, 0.8vw, 0.8vw);
    color: #f2f2f2;
}

.sg-tx-footer-grey {
    font-weight: 600;
    font-size: clamp(14px, 0.8vw, 0.8vw);
    color: #909090;
}

.sg-policy-box {
    display: flex;
    align-items: center;
    gap: clamp(12px, 1.3vw, 1.3vw);
    margin-bottom: clamp(16px, 1.5vw, 1.5vw);
}

.sg-company-box {
    display: flex;
    align-items: center;
    gap: clamp(8px, 0.6vw, 0.6vw);
    margin-bottom: clamp(0px, 0.4vw, 0.4vw);
}

.sg-company-info-box {
    display: flex;
    align-items: center;
    gap: clamp(8px, 1vw, 1vw);
}

/* header */
.sg-header {
    font-weight: 500;
    min-width: 100%;
    height: clamp(64px, 4.4vw, 4.4vw);
    position: fixed;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(6px);
    &.forMain {
        background-color: rgba(0, 0, 0, 0.15);
    }
}

.sg-header-download-icon {
    width: clamp(20px, 1vw, 1vw);
}

.sg-download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(91px, 6.3vw, 6.3vw);
    height: clamp(33px, 2.2vw, 2.2vw);
    border-radius: clamp(8px, 0.4vw, 0.4vw);
    background-color: #276e17;
    font-weight: 500;
    font-size: clamp(12px, 0.8vw, 0.8vw);
    color: white;
}

.sg-store-dropdownbox {
    position: absolute;
    margin-top: clamp(10px, 0.5vw, 0.5vw);
    width: clamp(91px, 6.3vw, 6.3vw);
    padding-top: clamp(4px, 0.2vw, 0.2vw);
    padding-bottom: clamp(4px, 0.2vw, 0.2vw);
    background-color: #ffffff;
    border: 1px solid #acacac;
    border-radius: clamp(8px, 0.4vw, 0.4vw);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.sg-store-dropdownbox-button {
    display: block;
    padding-left: clamp(16px, 0.8vw, 0.8vw);
    padding-right: clamp(16px, 0.8vw, 0.8vw);
    padding-top: clamp(8px, 0.4vw, 0.4vw);
    padding-bottom: clamp(8px, 0.4vw, 0.4vw);
    font-weight: 600;
    font-size: clamp(12px, 0.8vw, 0.8vw);
}

/* main-section-01 */
.main-section-01-bg {
    @apply bg-cover bg-center;
    position: relative;
    background-image: url("../assets/main_section_01.png");
    width: 100%;
    aspect-ratio: 1920 / 950;
}

.main-section-01-bg-color {
    position: absolute;
    background-color: #25ac07;
    width: 100%;
    aspect-ratio: 1920 / 800;
}

.main-section-01-bg-sm {
    @apply bg-cover bg-center;
    background-color: #25ac07;
    background-image: url("../assets/main_section_01_sm.png");
    width: 100%;
    aspect-ratio: 375 / 767;
    position: relative;
    z-index: 1;
}

.main-section-01-pd {
    padding-top: clamp(114px, 11vw, 11vw);
    padding-bottom: clamp(96px, 14vw, 14vw);
}

.main-section-01-pd-sm {
    width: 100%;
    height: 100%;
    padding-top: 114px;
    padding-bottom: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.main-section-01-logo {
    width: clamp(47px, 6.4vw, 6.4vw);
    margin-bottom: clamp(0px, 1vw, 1vw);
}

.main-section-01-logo-fake {
    width: clamp(47px, 6.4vw, 6.4vw);
    height: clamp(43px, 5.8vw, 5.8vw);
    margin-bottom: clamp(0px, 1vw, 1vw);
}

.main-section-01-logo-sticky {
    position: fixed;
    top: clamp(32px, 2.2vw, 2.2vw);
    transform: translateY(-50%);
    width: clamp(47px, 2.7vw, 2.7vw);
    z-index: 11;
    transition: all 300ms;
}

.main-section-01-title {
    font-size: clamp(24px, 2.6vw, 2.6vw);
    font-weight: 600;
    color: white;
    margin-bottom: clamp(0px, 1vw, 1vw);
}

.main-section-01-title-sm {
    font-size: 24px;
    font-weight: 600;
    color: white;
    text-align: center;
}

.main-section-01-subtitle {
    font-size: clamp(14px, 1.4vw, 1.4vw);
    color: #f2f2f2;
    margin-bottom: clamp(30px, 3vw, 3vw);
}

.sg-tx-title-main_section_01-sm {
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
}

.sg-tx-title-sm {
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
}

.sg-tx-subtitle-sm {
    font-weight: 500;
    font-size: 14px;
    color: #5a5a5a;
    white-space: nowrap;
}

.sg-tx-title-main_section_01-md {
    font-weight: 600;
    font-size: clamp(18px, 2vw, 2vw);
    white-space: nowrap;
}

.sg-tx-title-md {
    font-weight: 600;
    font-size: clamp(16px, 1.7vw, 1.7vw);
    white-space: nowrap;
}

.sg-tx-subtitle-md {
    font-weight: 500;
    font-size: clamp(14px, 1.3vw, 1.3vw);
    color: #5a5a5a;
    white-space: nowrap;
}

.main-section-01-kor-bg {
    @apply bg-cover bg-center;
    background-image: url("../assets/main_section_01_kor.png");
    padding-top: clamp(0px, 22vw, 22vw);
    padding-bottom: clamp(0px, 17.5vw, 17.5vw);
    width: 100%;
    aspect-ratio: 1920 / 950;
}

.main-section-01-kor-bg-sm {
    @apply bg-cover bg-center;
    background-image: url("../assets/main_section_01_kor_sm.png");
    padding-top: 150px;
    width: 100%;
    margin-top: -50px;
    z-index: 0;
}

.term-container div {
    font-size: clamp(16px, 1vw, 1vw);
}

.sg-policy-title-box {
    font-weight: 600;
    font-size: clamp(24px, 1.9vw, 1.9vw);
    margin-bottom: clamp(26px, 2.5vw, 2.5vw);
}

/* main-section-02 */
.sg-tx-set-modal-title {
    font-size: clamp(0px, 0.6vw, 0.6vw);
    color: #727272;
    padding-left: clamp(0px, 1.3vw, 1.3vw);
    padding-right: clamp(0px, 1.3vw, 1.3vw);
    margin-bottom: clamp(0px, 0.4vw, 0.4vw);
}
.sg-tx-set-modal-title-sm {
    font-size: 8px;
    color: #727272;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 6px;
}
.sg-main-section-02-container {
    width: 100%;
    padding-top: clamp(21px, 3.4vw, 3.4vw);
    padding-bottom: clamp(100px, 12.5vw, 12.5vw);
}
.sg-main-section-02-title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: clamp(32px, 4.2vw, 4.2vw);
}

.sg-tx-book-setting-title {
    font-weight: 500;
    font-size: clamp(12px, 1.1vw, 1.1vw);
    color: #25ac07;
    margin-bottom: clamp(10px, 0.9vw, 0.9vw);
}
.sg-font-setting-container {
    display: flex;
    gap: clamp(0px, 3vw, 3vw);
    align-items: center;
    justify-content: center;
}
.sg-font-mock-up-01-container {
    display: flex;
    align-items: center;
    gap: clamp(0px, 3vw, 3vw);
    padding-top: clamp(0px, 2.9vw, 2.9vw);
    padding-bottom: clamp(0px, 2.9vw, 2.9vw);
    padding-right: clamp(0px, 5.5vw, 5.5vw);
    background-color: #bfedb5;
    border-radius: clamp(0px, 1vw, 1vw);
}

.sg-font-mock-up-01-container-sm {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: 23px;
    background-color: #bfedb5;
    border-radius: 16px;
}

.sg-font-mock-up-01-img-box {
    position: relative;
    width: clamp(0px, 24.8vw, 24.8vw);
}

.sg-font-mock-up-01-img-box-sm {
    position: relative;
    width: 100%;
    z-index: 0;
}

.sg-font-set-box {
    padding-top: clamp(0px, 1.1vw, 1.1vw);
    padding-bottom: clamp(0px, 1.1vw, 1.1vw);
    background-color: white;
    border-radius: clamp(0px, 0.5vw, 0.5vw);
    box-shadow: 0 4.5px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #d9d9d9;
}

.sg-font-set-box-sm {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4.5px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #d9d9d9;
    z-index: 1;
    margin-right: -50px;
}

.sg-selected-list-box {
    padding-left: clamp(0px, 0.4vw, 0.4vw);
    padding-right: clamp(0px, 0.4vw, 0.4vw);
    margin-bottom: clamp(0px, 0.5vw, 0.5vw);
}

.sg-selected-list-box-sm {
    padding-left: 9px;
    padding-right: 9px;
    margin-bottom: 6px;
}

.sg-unselected-list {
    padding-top: clamp(0px, 0.4vw, 0.4vw);
    padding-bottom: clamp(0px, 0.4vw, 0.4vw);
    padding-left: clamp(0px, 0.8vw, 0.8vw);
    padding-right: clamp(0px, 0.5vw, 0.5vw);
    font-size: clamp(0px, 0.8vw, 0.8vw);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sg-unselected-list-sm {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 9px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sg-selected-list {
    padding-top: clamp(0px, 0.4vw, 0.4vw);
    padding-bottom: clamp(0px, 0.4vw, 0.4vw);
    padding-left: clamp(0px, 0.8vw, 0.8vw);
    padding-right: clamp(0px, 0.5vw, 0.5vw);
    font-size: clamp(0px, 0.8vw, 0.8vw);
    font-weight: 500;
    background-color: rgba(27, 128, 5, 0.04);
    border-radius: clamp(0px, 0.2vw, 0.2vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sg-selected-list-sm {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 9px;
    font-weight: 500;
    background-color: rgba(27, 128, 5, 0.04);
    border-radius: clamp(0px, 0.2vw, 0.2vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sg-selected-icon {
    width: clamp(0px, 1.3vw, 1.3vw);
    opacity: 0;
    &.active {
        opacity: 1;
    }
}

.sg-selected-icon-sm {
    width: 13px;
    opacity: 0;
    &.active {
        opacity: 1;
    }
}

.sg-font-mock-up-02-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: clamp(0px, 6.3vw, 6.3vw);
    padding-bottom: clamp(0px, 6.3vw, 6.3vw);
    padding-right: clamp(0px, 12vw, 12vw);
    padding-left: clamp(0px, 5.9vw, 5.9vw);
    background-color: #e7f5e4;
    border-radius: clamp(0px, 1vw, 1vw);
}

.sg-font-mock-up-02-container-sm {
    width: 100%;
    margin-bottom: 24px;
    padding-top: 40px;
    padding-bottom: 114px;
    padding-right: 81px;
    background-color: #e7f5e4;
    border-radius: 16px;
}

.sg-font-mock-up-01-img-box {
    position: relative;
    width: clamp(0px, 24.8vw, 24.8vw);
}

.sg-font-mock-up-02-img-box {
    position: relative;
    width: clamp(0px, 30vw, 30vw);
}

.sg-select-btn-box {
    padding: clamp(0px, 0.2vw, 0.2vw);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: clamp(0px, 0.2vw, 0.2vw);
    border-radius: clamp(0px, 0.5vw, 0.5vw);
    background-color: #f5f5f5;
}

.sg-select-btn-box-sm {
    padding: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    background-color: #f5f5f5;
}

.sg-selected-btn {
    border-radius: clamp(0px, 0.5vw, 0.5vw);
    border: solid 1.5px #25ac07;
    background-color: #fff;
    padding-top: clamp(0px, 0.5vw, 0.5vw);
    padding-bottom: clamp(0px, 0.5vw, 0.5vw);
    padding-right: clamp(0px, 1.1vw, 1.1vw);
    padding-left: clamp(0px, 1.1vw, 1.1vw);
    font-weight: 500;
    font-size: clamp(0px, 0.7vw, 0.7vw);
    color: #25ac07;
    white-space: nowrap;
    display: flex;
    gap: clamp(0px, 0.3vw, 0.3vw);
    align-items: center;
    justify-content: center;
}

.sg-selected-btn-sm {
    border-radius: 6px;
    border: solid 1.5px #25ac07;
    background-color: #fff;
    padding: 7px 13px;
    font-weight: 500;
    font-size: 8px;
    color: #25ac07;
    white-space: nowrap;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.sg-selected-img-filter {
    filter: invert(47%) sepia(24%) saturate(4886%) hue-rotate(77deg) brightness(99%) contrast(94%);
}

.sg-unselected-btn {
    border-radius: clamp(0px, 0.5vw, 0.5vw);
    border: solid 1.5px #f5f5f5;
    padding-top: clamp(0px, 0.5vw, 0.5vw);
    padding-bottom: clamp(0px, 0.5vw, 0.5vw);
    padding-right: clamp(0px, 1.1vw, 1.1vw);
    padding-left: clamp(0px, 1.1vw, 1.1vw);
    font-size: clamp(0px, 0.7vw, 0.7vw);
    color: #909090;
    white-space: nowrap;
    display: flex;
    gap: clamp(0px, 0.3vw, 0.3vw);
    align-items: center;
    justify-content: center;
}

.sg-unselected-btn-sm {
    border-radius: 6px;
    border: solid 1.5px #f5f5f5;
    background-color: #f5f5f5;
    padding: 7px 13px;
    font-weight: 500;
    font-size: 8px;
    color: #909090;
    white-space: nowrap;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.sg-write-mode-set-box {
    position: absolute;
    top: clamp(0px, 11.2vw, 11.2vw);
    right: clamp(0px, 4.2vw, 4.2vw);
    background-color: white;
    border-radius: clamp(0px, 0.5vw, 0.5vw);
    box-shadow: 0 4.5px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #d9d9d9;
    padding-top: clamp(0px, 1vw, 1vw);
    padding-bottom: clamp(0px, 1.3vw, 1.3vw);
    width: clamp(0px, 14.3vw, 14.3vw);
}

.sg-write-mode-set-box-sm {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4.5px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #d9d9d9;
    padding-top: 16px;
    padding-bottom: 19px;
    z-index: 1;
    position: absolute;
    bottom: -74px;
    right: -60px;
}

.sg-write-mode-progress-img {
    width: clamp(0px, 11.8vw, 11.8vw);
}

.sg-write-mode-progress-img-sm {
    width: 177px;
}

.sg-write-set-icon {
    width: clamp(0px, 1.3vw, 1.3vw);
    margin-right: clamp(0px, 0.3vw, 0.3vw);
}

.sg-write-set-icon-sm {
    width: 20px;
}

/* mainSection03 */
.sg-main-section-03-container {
    width: 100%;
    padding-bottom: clamp(100px, 12.5vw, 12.5vw);
}
.sg-main-section-03-title-box {
    margin-bottom: clamp(0px, 2vw, 2vw);
}
.sg-main-section-03-title-box-sm {
    margin-bottom: 32px;
}
.sg-main_section-03-books-img {
    width: 100%;
}

/* mainSection04 */
.sg-main-section-04-slider-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sg-main-section-04-slider-title {
    color: #fff;
    font-weight: 600;
    font-size: clamp(18px, 2vw, 2vw);
    white-space: nowrap;
    margin-bottom: clamp(20px, 2.2vw, 2.2vw);
}

.sg-main-section-04-slider-text-box {
    display: flex;
    align-items: center;
    gap: clamp(6px, 0.5vw, 0.5vw);
    margin-bottom: clamp(16px, 1.7vw, 1.7vw);
}

.sg-main-section-04-slider-text {
    font-weight: 500;
    font-size: clamp(14px, 1.3vw, 1.3vw);
    color: white;
}

.sg-main-section-04-slider-text-icon {
    width: clamp(20px, 1.7vw, 1.7vw);
}

.sg-main-section-04-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: clamp(16px, 2.2vw, 2.2vw);
}

.sg-main-section-04-slider-btn {
    width: clamp(42px, 4.1vw, 4.1vw);
    &.disabled {
        opacity: 0.3;
    }
}

.sg-main-section-04-slider-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &.active {
        opacity: 1;
    }
}

.sg-main-section-04-slider-dot-box {
    display: flex;
    gap: clamp(8px, 0.4vw, 0.4vw);
    margin-bottom: clamp(14px, 0.8vw, 0.8vw);
}

.sg-main-section-04-slider-dot {
    width: clamp(8px, 0.4vw, 0.4vw);
    height: clamp(8px, 0.4vw, 0.4vw);
    background-color: #ffffff;
    opacity: 0.3;
    border-radius: clamp(4px, 0.2vw, 0.2vw);
    &.active {
        opacity: 1;
        width: clamp(24px, 1.3vw, 1.3vw);
    }
}

.sg-main-section-04-slider-ver2-text {
    font-size: clamp(12px, 0.8vw, 0.8vw);
    color: white;
    opacity: 0;
    &.active {
        opacity: 0.8;
    }
}

/* mainSection05 */
.sg-main-section-05-container {
    width: 100%;
    padding-top: clamp(60px, 10.4vw, 10.4vw);
    padding-bottom: clamp(80px, 12.5vw, 12.5vw);
}

.sg-main-section-05-title-box {
    font-weight: 600;
    font-size: clamp(18px, 2vw, 2vw);
    margin-bottom: clamp(32px, 4.2vw, 4.2vw);
}

.sg-main-section-05-notice-linktitle {
    color: #276e17;
    font-size: clamp(12px, 1.1vw, 1.1vw);
    font-weight: 500;
    margin-bottom: clamp(8px, 0.8vw, 0.8vw);
}

.sg-main-section-05-notice-text {
    font-size: clamp(14px, 1.4vw, 1.4vw);
    font-weight: 600;
    margin-bottom: clamp(8px, 0.6vw, 0.6vw);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden; /* 텍스트가 넘칠 때 숨김 처리 */
    text-overflow: ellipsis; /* 말줄임표 처리 */
}

.sg-main-section-05-notice-description {
    font-size: clamp(12px, 1.1vw, 1.1vw);
    color: #5a5a5a;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden; /* 텍스트가 넘칠 때 숨김 처리 */
    text-overflow: ellipsis; /* 말줄임표 처리 */
}

.sg-main-section-05-notice-arrow {
    width: clamp(12px, 1.3vw, 1.3vw);
}

.sg-main-section-05-notice-arrow-text {
    font-size: clamp(10px, 1vw, 1vw);
    font-weight: 500;
    color: #276e17;
}

/* mainSection06 */
.sg-main-section-06-container {
    width: 100%;
    aspect-ratio: 1920/462;
    @apply bg-cover bg-center;
    background-image: linear-gradient(
            transparent 24.5px,
            rgb(255, 255, 255, 0.3) 25px,
            transparent 26px
        ),
        linear-gradient(90deg, transparent 24.5px, rgb(255, 255, 255, 0.3) 25px, transparent 26px);
    background-size: 32px 32px;
    background-color: #25ac07;
    padding-top: clamp(78px, 6.3vw, 6.3vw);
    padding-bottom: clamp(100px, 7.3vw, 7.3vw);
}

.sg-main-section-06-sm-text {
    font-size: clamp(14px, 1.3vw, 1.3vw);
    color: #ffffff;
    margin-bottom: clamp(6px, 0.5vw, 0.5vw);
}

.sg-main-section-06-md-text {
    font-weight: 600;
    font-size: clamp(24px, 2.5vw, 2.5vw);
    color: #ffffff;
    margin-bottom: clamp(30px, 2.5vw, 2.5vw);
}

/* 스토어 버튼 */
.sg-store-button {
    display: flex;
    align-items: center;
    padding: clamp(11px, 0.8vw, 0.8vw) clamp(20px, 1.8vw, 1.8vw);
    border-radius: clamp(10px, 0.6vw, 0.6vw);
    backdrop-filter: blur(15.5px);
    background-color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
    font-size: clamp(14px, 0.9vw, 0.9vw);
    color: white;
}

.sg-store-btn-icon {
    width: clamp(24px, 1.3vw, 1.3vw);
    margin-right: clamp(8px, 0.4vw, 0.4vw);
}

/* policy */
.sg-policy-container {
    padding-top: clamp(104px, 6vw, 6vw);
    padding-bottom: clamp(100px, 8.3vw, 8.3vw);
}

/* 패딩 마진 gap */

.sg-px-24 {
    padding-left: clamp(0px, 1.3vw, 1.3vw);
    padding-right: clamp(0px, 1.3vw, 1.3vw);
}

.sg-my-15 {
    margin-bottom: clamp(0px, 0.8vw, 0.8vw);
    margin-top: clamp(0px, 0.8vw, 0.8vw);
}

.sg-mb-8 {
    margin-bottom: clamp(0px, 0.4vw, 0.4vw);
}

.sg-mb-12 {
    margin-bottom: clamp(0px, 0.6vw, 0.6vw);
}

.sg-mb-16 {
    margin-bottom: clamp(0px, 0.8vw, 0.8vw);
}

.sg-mb-18 {
    margin-bottom: clamp(0px, 0.9vw, 0.9vw);
}

.sg-mb-20 {
    margin-bottom: clamp(0px, 1vw, 1vw);
}

.sg-mb-24 {
    margin-bottom: clamp(12px, 1.3vw, 1.3vw);
}

.sg-mb-26 {
    margin-bottom: clamp(0px, 1.4vw, 1.4vw);
}

.sg-gap-20 {
    gap: clamp(10px, 1vw, 1vw);
}

.sg-gap-32 {
    gap: clamp(0px, 1.7vw, 1.7vw);
}

.sg-gap-60 {
    gap: clamp(0px, 3vw, 3vw);
}

.sg-gap-80 {
    gap: clamp(0px, 4.2vw, 4.2vw);
}

/* 컬러 */
.sg-greyish-brown {
    color: #5a5a5a;
}

.sg-main-des-white {
    color: #f2f2f2;
}

.sg-main-store-btn-black {
    color: #292929;
}

.sg-grass-green {
    color: #25ac07;
}

@layer utilities {
    /* 모바일 */
    .sg-px-320-sm {
        padding-left: clamp(16px, 0.8vw, 16px);
        padding-right: clamp(16px, 0.8vw, 16px);
    }

    .sg-header-logo {
        width: clamp(47px, 2.7vw, 2.7vw);
        opacity: 100;
    }

    .sg-main-section-04-container-sm {
        width: 100%;
        box-shadow: 0 4.5px 30px 0 rgba(39, 110, 23, 0.08);
        background-color: #25ac07;
        padding: 48px 5px 35px;
    }

    .sg-main-section-04-slider-img-stack-sm {
        position: relative;
        width: clamp(283px, 14.7vw, 283px);
        height: clamp(368px, 19.2vw, 368px);
    }

    .sg-main-section-05-notice-card-sm {
        width: 210px;
        height: 100%;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4.5px 30px 0 rgba(39, 110, 23, 0.08);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .sg-main-section-05-notice-card {
        aspect-ratio: 410 / 300;
        padding: clamp(20px, 2.1vw, 2.1vw);
        border-radius: clamp(10px, 0.8vw, 0.8vw);
        box-shadow: 0 4.5px 30px 0 rgba(39, 110, 23, 0.08);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    /* md: "769px" */
    @screen md {
        .sg-px-320-md {
            padding-left: clamp(0px, 17vw, 17vw);
            padding-right: clamp(0px, 17vw, 17vw);
        }

        .sg-header-logo-md {
            visibility: visible;
            width: clamp(47px, 2.7vw, 2.7vw);
            opacity: 100;
            &.forMain {
                opacity: 0;
            }
        }

        .sg-main-section-04-container-md {
            width: 100%;
            @apply bg-cover bg-center;
            background-image: url("../assets/main_section_04.png");
            box-shadow: 0 4.5px 30px 0 rgba(39, 110, 23, 0.08);
            background-color: #25ac07;
            padding: clamp(0px, 6.3vw, 6.3vw) clamp(0px, 15.2vw, 15.2vw);
        }

        .sg-main-section-04-slider-img-stack-md {
            position: relative;
            width: clamp(0px, 46.9vw, 46.9vw);
            height: clamp(0px, 36vw, 36vw);
        }

        .sg-main-section-05-slider-container-md {
            width: 100%;
            padding-left: clamp(0px, 10.4vw, 10.4vw);
            padding-right: clamp(0px, 10.4vw, 10.4vw);
            display: flex;
            align-items: center;
            gap: clamp(20px, 2.2vw, 2.2vw);
        }
    }

    /* xl: "1280px" */
    @screen xl {
    }
}
